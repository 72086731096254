import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledNumbersToRevers = styled.div``;

export const StyledNumbersToReversItems = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const StyledNumbersToReversItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-basis: 50%;
  padding-bottom: ${Rem(70)};
  @media (${BP.ipad}) {
    flex-basis: 25%;
  }
`;

export const StyledNumbersToReversDivider = styled.p`
  height: ${Rem(1)};
  background: #AAA9A9;
  width: ${Rem(150)};
  margin-top: ${Rem(10)};
  margin-bottom: ${Rem(10)};

  @media (${BP.ipad}) {
    margin-top: ${Rem(15)};
    margin-bottom: ${Rem(15)};
  }
`;

export const StyledNumbersToReversItemValue = styled.div`
  font-size: ${Rem(24)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(3)};
  color: ${(props) => props.isGreen ? Colors.greenPrimary : Colors.redPrimary};
  padding-bottom: ${Rem(28)};
  align-items: center;
  justify-content: flex-start;

  @media (${BP.ipad}) {
    font-size: ${Rem(35)};
    padding-bottom: 0;
  }
`;

export const StyledNumbersToReversItemLabel = styled.div`
  font-weight: 400;
  font-size: ${Rem(18)};
  line-height: ${Rem(24)};
  text-align: center;

  @media (${BP.ipad}) {
    padding: 0 ${Rem(32)} 0 ${Rem(16)};
  }
`;
